import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import loadPlayerJS from '../../scripts/loadplayer.js'
import LangSelector from './LangSelector'

let player = null

function VideoPlayer({ agenda }) {
  const [hls, setHls] = useState(null)
  const [iframe, setIframe] = useState(null)

  function playJS(hls, online) {
    if (hls && online) {
      loadPlayerJS(() => {
        player = new window.Playerjs({
          id: 'player',
          file: hls,
          autoplay: 1,
        })
      })
    } else if (!online && player) {
      player.api('destroy')
    }
  }

  useEffect(() => {
    console.log('Cambio el HLS')
    setHls(agenda.hls)
    playJS(agenda.hls, agenda.online)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda.hls, agenda.online])

  useEffect(() => {
    console.log('Cambio el IFRAME', agenda.player_iframe && agenda.online, agenda.player_iframe, agenda.online)
    if (agenda.player_iframe && agenda.online) {
      console.log('SET IFRAME')
      setIframe(agenda.player_iframe)
    } else {
      if (!agenda.online) {
        setIframe(null)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agenda.player_iframe, agenda.online])

  function handleOnChange(hls) {
    setHls(hls)
    playJS(hls, agenda.online)
  }

  function createMarkup() {
    console.log('agenda.player_iframe', iframe, agenda.id, agenda.player_iframe, agenda.online)
    if (iframe) {
      return { __html: iframe }
    }
  }

  return (
    <>
      <div
        className="shadow-lg h-auto bg-black mb-4 bg-contain bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${agenda.banner})` }}
      >
        <div id="player">
          <div className="relative" style={{ paddingBottom: '56.25%' }} dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
      </div>
      {hls && <LangSelector onChange={handleOnChange} agenda={agenda}></LangSelector>}
    </>
  )
}

/**
 * PropTypes
 */
VideoPlayer.propTypes = {
  agenda: PropTypes.shape({
    hls: PropTypes.string,
    player_iframe: PropTypes.string,
    online: PropTypes.number.isRequired,
    banner: PropTypes.string.isRequired,
  }),
}

/**
 * Exports
 */

export default VideoPlayer
