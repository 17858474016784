import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { get, isEmpty, isNil } from 'lodash'
import PropTypes from 'prop-types'

import apiClient from '~/lib/api-client'
import * as auth from '~/lib/auth'
import { bugsnag } from '~/lib/bugsnag'
import * as chat from '~/lib/chat'

/**
 * AuthContext
 */

export const AuthContext = createContext()

/**
 * AuthProvider
 */

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [cometChatUser, setCometChatUser] = useState({})
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const load = async () => {
      const token = auth.getToken()
      await chat.init()

      if (isNil(token)) {
        setInitialized(true)
        setUser(null)

        return
      }

      try {
        const response = await apiClient.get('/api/users/whoami')

        if (!isEmpty(response)) {
          setUser({ ...response.data, token })

          const isInboxActive = get(response, 'data.inbox_active')
          if (isInboxActive) {
            const chatAuthToken = get(response, 'data.chat_auth_token')
            const user = await chat.login(chatAuthToken)
            const userData = await CometChat.getUser(user.uid)
            setCometChatUser(userData)
          }
        }
      } catch (error) {
        bugsnag.notify(error)
        throw error
      } finally {
        setInitialized(true)
      }
    }

    load()
  }, [])

  const login = useCallback(async (email, password) => {
    try {
      const { data } = await apiClient.post('/api/users/login', { email, password })
      if (data.user.last_name === 'NEED_COMPLETE_REGISTRATION') {
        return data.user
      }

      auth.setToken(data.user.token)
      setUser(data.user)
      const userId = get(data, 'user.id', '')
      const userEmail = get(data, 'user.email', '')
      const userFullName = get(data, 'user.full_name', '')
      bugsnag.setUser(userId, userEmail, userFullName)
      if (data.user.inbox_active) {
        const user = await chat.login(data.user.chat_auth_token)
        const userData = await CometChat.getUser(user.uid)
        setCometChatUser(userData)
      }

      return false
    } catch (error) {
      bugsnag.notify(error)
      throw error
    }
  }, [])

  const loginToCometChat = useCallback(async () => {
    const data = await chat.login(user.chat_auth_token)
    setCometChatUser(data)
  }, [user])

  const signup = useCallback(
    async (first_name, last_name, company_fantasy, company_role, email, password, externalId) => {
      try {
        const { data } = await apiClient.post('/api/users/signup', {
          first_name,
          last_name,
          company_fantasy,
          company_role,
          email,
          password,
          externalId,
        })
        auth.setToken(data.user.token)
        setUser(data.user)
        const cometChatUser = await chat.login(data.user.chat_auth_token)
        setCometChatUser(cometChatUser)
      } catch (error) {
        bugsnag.notify(error)
        throw error
      }
    },
    []
  )

  const updateProfile = useCallback(async (email, token, first_name, last_name, company_fantasy, company_role) => {
    try {
      const { data } = await apiClient.post('/api/users/loginUpdate', {
        email,
        token,
        first_name,
        last_name,
        company_fantasy,
        company_role,
      })
      auth.setToken(data.user.token)
      setUser(data.user)
      const cometChatUser = await chat.login(data.user.chat_auth_token)
      setCometChatUser(cometChatUser)
    } catch (error) {
      bugsnag.notify(error)
      throw error
    }
  }, [])

  const forgotPassword = useCallback(async email => {
    try {
      const { data } = await apiClient.post('/api/users/recover', { email })

      return data
    } catch (error) {
      bugsnag.notify(error)
      throw error
    }
  }, [])

  const recoveryPassword = useCallback(async (password, token) => {
    try {
      const { data } = await apiClient.post('/api/users/reset', {
        password,
        token,
      })

      return data
    } catch (error) {
      bugsnag.notify(error)
      throw error
    }
  }, [])

  const mutateUser = useCallback(
    newUser => {
      try {
        setUser({ ...user, ...newUser })
      } catch (error) {
        bugsnag.notify(error)
        throw error
      }
    },
    [user]
  )

  const logout = useCallback(() => {
    auth.removeToken()
    setUser(null)
    setCometChatUser({})
  }, [])

  const isLoggedIn = !isEmpty(user)

  const value = useMemo(
    () => ({
      user,
      cometChatUser,
      setCometChatUser,
      signup,
      login,
      updateProfile,
      loginToCometChat,
      logout,
      forgotPassword,
      recoveryPassword,
      mutateUser,
      isLoggedIn,
      initialized,
    }),
    [
      user,
      cometChatUser,
      signup,
      login,
      updateProfile,
      loginToCometChat,
      logout,
      forgotPassword,
      recoveryPassword,
      mutateUser,
      isLoggedIn,
      initialized,
    ]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth should be inside AuthContext provider')
  }

  return context
}
