import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { CometChat } from '@cometchat-pro/chat'
import { get, includes, isEmpty, reduce } from 'lodash'
import PropTypes from 'prop-types'

import { ConversationListManager } from '~/components/CometChat/components/CometChatConversationList/controller'
import * as enums from '~/components/CometChat/util/enums'
import SvgAvatar from '~/components/CometChat/util/svgavatar'
import { bugsnag } from '~/lib/bugsnag'
import { useAuth } from './auth'

/**
 * Helpers
 */

let conversationManager = new ConversationListManager()

const setAvatar = conversation => {
  const isUserConversation = conversation.conversationType === 'user'
  const isGroupConversation = conversation.conversationType === 'group'
  const userAvatar = get(conversation, 'conversationWith.avatar')
  const groupIcon = get(conversation, 'conversationWith.icon')
  const conversationName = get(conversation, 'conversationWith.name', '')

  if (isUserConversation && !userAvatar) {
    const uid = get(conversation, 'conversationWith.uid')
    const char = conversationName.charAt(0).toUpperCase()

    return SvgAvatar.getAvatar(uid, char)
  }

  if (isUserConversation && userAvatar) {
    return userAvatar
  }

  if (isGroupConversation && !groupIcon) {
    const guid = get(conversation, 'conversationWith.guid')
    const char = conversationName.charAt(0).toUpperCase()

    return SvgAvatar.getAvatar(guid, char)
  }
}

/**
 * ChatContext
 */

const ChatContext = createContext()

/**
 * ChatProvider
 */

export const ChatProvider = ({ children }) => {
  const { cometChatUser, user } = useAuth()
  const [conversations, setConversations] = useState([])
  const initialConversations = useRef(conversations)
  const [selectedConversation, setSelectedConversation] = useState({})

  const getConversations = useCallback(async () => {
    try {
      const conversationList = await conversationManager.fetchNextConversation()
      conversationList.forEach(conversation => {
        if (conversation.conversationType === 'user' && !conversation.conversationWith.avatar) {
          conversation.conversationWith.avatar = setAvatar(conversation)
        } else if (conversation.conversationType === 'group' && !conversation.conversationWith.icon) {
          conversation.conversationWith.icon = setAvatar(conversation)
        }
      })
      const newConversations = [...initialConversations.current, ...conversationList]
      initialConversations.current = newConversations
      setConversations(newConversations)
    } catch (error) {
      bugsnag.notify(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const unreadMessageCount = useMemo(
    () =>
      reduce(
        conversations,
        (sum, n) => {
          const shouldFilter =
            n.conversationId === 'group_plenaria' ||
            includes(n.conversationId, 'group_session_') ||
            n.conversationId.indexOf('coloquio_soporte') >= 0
          const next = shouldFilter ? 0 : n.unreadMessageCount

          return sum + next
        },
        0
      ),
    [conversations]
  )

  const updateConversation = useCallback(
    async (message, group, operator, options = {}) => {
      const conversation = await CometChat.CometChatHelper.getConversationFromMessage(message)
      const conversationlist = [...conversations]
      const conversationKey = conversationlist.findIndex(c => c.conversationId === conversation.conversationId)

      if (conversationKey > -1) {
        const conversationObj = { ...conversationlist[conversationKey] }
        const conversationWithObj = { ...conversationObj.conversationWith }
        const lastMessageObj = { ...conversationObj.lastMessage, ...message }
        let unreadMessageCount = parseInt(conversationObj.unreadMessageCount)

        let newConversationWithObj = conversationWithObj
        // group listeners response handler
        if (group) {
          let membersCount = parseInt(conversationWithObj.membersCount)
          let scope = conversationWithObj.scope
          let hasJoined = conversationWithObj.hasJoined

          // member added to group / member joined
          if (operator === 'increment') {
            membersCount = membersCount + 1
          } else if (operator === 'decrement') {
            // member kicked, banned / member left
            membersCount = membersCount - 1
          }

          // if the loggedin user has been kicked from / or added to group / scope is changed
          const optionsUserUid = get(options, 'user.uid')
          if (options && cometChatUser.uid === optionsUserUid) {
            if (options.scope) {
              scope = options.scope
            } else if (options.hasJoined) {
              hasJoined = options.hasJoined
            }
          }

          newConversationWithObj = {
            ...conversationWithObj,
            membersCount: membersCount,
            scope: scope,
            hasJoined: hasJoined,
            avatar: setAvatar(conversationObj),
          }
        }

        if (
          options.isUserListConversation ||
          (selectedConversation && selectedConversation.conversationId === conversation.conversationId)
        ) {
          unreadMessageCount = 0
        } else {
          unreadMessageCount = unreadMessageCount + 1
        }

        let newConversationObj = {
          ...conversationObj,
          conversationWith: newConversationWithObj,
          lastMessage: lastMessageObj,
          unreadMessageCount: unreadMessageCount,
          avatar: setAvatar(conversationObj),
        }
        conversationlist.splice(conversationKey, 1)
        conversationlist.unshift(newConversationObj)
        initialConversations.current = conversationlist
        setConversations(conversationlist)
      } else {
        const conversationObj = { ...conversation }
        const conversationWithObj = { ...conversationObj.conversationWith }
        const avatar = setAvatar(conversationObj)
        const lastMessageObj = { ...message }
        const unreadMessageCount = 1

        const isGroup = !isEmpty(group) && !isEmpty(options)

        const newConversationWithObj = {
          ...conversationWithObj,
          avatar: avatar,
          ...(isGroup && { scope: options.scope }),
          ...(isGroup && { hasJoined: options.hasJoined }),
        }
        const newConversationObj = {
          ...conversationObj,
          conversationWith: newConversationWithObj,
          lastMessage: lastMessageObj,
          unreadMessageCount: unreadMessageCount,
        }
        conversationlist.unshift(newConversationObj)
        initialConversations.current = conversationlist
        setConversations(conversationlist)
      }
    },
    [cometChatUser, conversations, selectedConversation]
  )

  const updateUser = useCallback(
    user => {
      const conversationlist = [...conversations]
      const conversationKey = conversationlist.findIndex(
        conversationObj =>
          conversationObj.conversationType === 'user' && conversationObj.conversationWith.uid === user.uid
      )

      if (conversationKey > -1) {
        let conversationObj = { ...conversationlist[conversationKey] }
        let conversationWithObj = { ...conversationObj.conversationWith, status: user.getStatus() }

        let newConversationObj = { ...conversationObj, conversationWith: conversationWithObj }
        conversationlist.splice(conversationKey, 1, newConversationObj)
        setConversations(conversationlist)
      }
    },
    [conversations]
  )

  const handleConversationUpdate = useCallback(
    (key, item, message, options) => {
      switch (key) {
        case enums.TEXT_MESSAGE_RECEIVED:
        case enums.MEDIA_MESSAGE_RECEIVED:
        case enums.CUSTOM_MESSAGE_RECEIVED:
        case enums.GROUP_MEMBER_UNBANNED:
        case enums.INCOMING_CALL_RECEIVED:
        case enums.INCOMING_CALL_CANCELLED:
          updateConversation(message)
          break
        case enums.USER_ONLINE:
        case enums.USER_OFFLINE:
          updateUser(item)
          break
        case enums.GROUP_MEMBER_SCOPE_CHANGED:
          updateConversation(message, item, undefined, options)
          break
        case enums.GROUP_MEMBER_KICKED:
          updateConversation(message, item, 'decrement', options)
          break
        case enums.GROUP_MEMBER_BANNED:
          updateConversation(message, item, 'decrement')
          break
        case enums.GROUP_MEMBER_ADDED:
          updateConversation(message, item, 'increment', options)
          break
        case enums.GROUP_MEMBER_LEFT:
          updateConversation(message, item, 'decrement')
          break
        case enums.GROUP_MEMBER_JOINED:
          updateConversation(message, item, 'increment')
          break
        default:
          break
      }
    },
    [updateConversation, updateUser]
  )

  const setNewConversations = useCallback(newConversations => {
    initialConversations.current = newConversations
    setConversations(newConversations)
  }, [])

  useEffect(() => {
    conversationManager = new ConversationListManager()
  }, [])

  useEffect(() => {
    conversationManager.attachListeners(handleConversationUpdate)

    return () => {
      conversationManager.removeListeners()
    }
  }, [handleConversationUpdate])

  useEffect(() => {
    if (!isEmpty(cometChatUser) && user.inbox_active) {
      getConversations()
    }
  }, [cometChatUser, getConversations, user.inbox_active])

  useEffect(() => {
    const title = `${unreadMessageCount > 0 ? `(${unreadMessageCount}) ` : ''}${process.env.REACT_APP_EVENT_SITE_TITLE}`
    document.title = title
  }, [unreadMessageCount])

  const value = useMemo(
    () => ({
      getConversations,
      unreadMessageCount,
      conversations,
      setConversations,
      setNewConversations,
      selectedConversation,
      setSelectedConversation,
      updateConversation,
    }),
    [conversations, getConversations, selectedConversation, setNewConversations, unreadMessageCount, updateConversation]
  )

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>
}

/**
 * PropTypes
 */

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

/**
 * Hooks
 */

export function useChat() {
  const context = React.useContext(ChatContext)
  if (!context) {
    throw new Error('useAuth should be inside ChatContext provider')
  }

  return context
}
