import React from 'react'

import MobileHeader from '~/components/guest/MobileHeader'
/**
 * Hero
 */
import { eventFolder } from '~/lib/multievent'
import Background from './hero/Background'

const Hero = () => (
  <>
    <div className="relative hidden lg:w-1/2 bg-heading lg:flex items-center justify-center">
      <div className="absolute top-0 bottom-0 left-0 right-0 bg-heading z-0">
        <Background image={process.env.REACT_APP_EVENT_LOGIN_TEMPLATE === 'image'} />
      </div>
      <img
        className="logo absolute z-2 lg:w-login-icon-lg w-login-icon"
        src={eventFolder('guest/icon_logo.svg')}
        alt="Logo"
      />
      <img
        className="date absolute z-2 lg:w-login-bl-lg w-login-bl"
        src={eventFolder('guest/login_bottom_left.png')}
        alt="Fechas del evento"
      />
      <img
        className="anniversary absolute z-2 lg:w-login-br-lg w-login-br"
        src={eventFolder('guest/login_bottom_right.png')}
        alt="Logo"
      />
      <img className="w-login-center z-2" src={eventFolder('guest/login_hero.png')} alt="Texto" />
    </div>
    <MobileHeader image={process.env.REACT_APP_EVENT_LOGIN_TEMPLATE === 'image'} />
    <style jsx>{`
      .logo {
        top: 0;
        left: 25px;
      }

      .date {
        left: 30px;
        bottom: 30px;
      }

      .anniversary {
        right: 25px;
        bottom: 5px;
      }
    `}</style>
  </>
)

/**
 * Exports
 */

export default Hero
