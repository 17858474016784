import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import resources from './locales'

/**
 * i18n
 */

i18n.use(initReactI18next).init({
  fallbackLng: process.env.REACT_APP_EVENT_I18N || 'es',
  resources,
  lng: process.env.REACT_APP_EVENT_I18N || 'es',
  interpolation: {
    escapeValue: false,
  },
})

/**
 * Exports
 */

export default i18n
