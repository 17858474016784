import React from 'react'
import Lottie from 'react-lottie'
import PropTypes from 'prop-types'

import { eventFolder } from '~/lib/multievent'
import * as animationData from '~/lotties/login_background.json'
/**
 * Background
 */

const Background = ({ image }) => {
  let backgroundImage = ''

  if (image) {
    backgroundImage = `url('${eventFolder('guest/background.jpg')}')`
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 bg-heading z-0 bg-cover" style={{ backgroundImage }}>
      {!image && (
        <Lottie
          isClickToPauseDisabled
          resizeMode="cover"
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData.default,
          }}
        />
      )}
      {image && <div className="absolute z-1 top-0 inset-x-0 bg-primary w-full h-20" alt="Logo" />}
      {image && <div className="absolute z-1 bottom-0 inset-x-0 bg-primary w-full h-20" alt="Logo" />}
    </div>
  )
}

/**
 * PropTypes
 */

Background.propTypes = {
  image: PropTypes.string,
}

/**
 * Exports
 */

export default Background
