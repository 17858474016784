import React, { useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { filter, isEmpty, map, uniq } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'

import ProgramAgenda from '~/components/home/ProgramAgenda'
import ProgramTabs from '~/components/home/ProgramTabs'
import Heading from '../Heading'

/**
 * Helpers
 */

const getDayFromDate = date => {
  return moment(date)
    .subtract('4', 'hours')
    .format('YYYY/MM/DD')
}

const getAgendaDays = agendas => uniq(map(agendas, item => getDayFromDate(item.start_date)))

const getAgendaByDates = agendas => {
  const dates = getAgendaDays(agendas)

  return map(dates, day => filter(agendas, item => getDayFromDate(item.start_date) === day))
}

/**
 * Program
 */

function Program({ agendas }) {
  const { t } = useTranslation()
  const dates = getAgendaDays(agendas)
  const [selectedDate, setDate] = useState(0)
  const agendasByDates = getAgendaByDates(agendas)

  const handleTabClick = useCallback(index => {
    setDate(index)
    ReactGA.event({ category: 'Home', action: 'TabChange', label: index })
  }, [])

  useEffect(() => {
    for (let i = 0; i < dates.length; i++) {
      if (dates[i] === moment().format('YYYY/MM/DD')) {
        setDate(i)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="mb-8">
      <Heading level={2} size="lg" className="my-8">
        {t('home:program')}
      </Heading>
      <div className="card bg-white rounded-lg shadow">
        <div className="h-full">
          <div className="w-full px-4">
            <div className="relative z-0">
              {dates && <ProgramTabs dates={dates} selectedDate={selectedDate} onTabClick={handleTabClick} />}
              {!isEmpty(agendasByDates) &&
                agendasByDates[selectedDate].map((agenda, index) => <ProgramAgenda key={index} agenda={agenda} />)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * PropTypes
 */

Program.propTypes = {
  agendas: PropTypes.array.isRequired,
}

/**
 * Exports
 */

export default Program
