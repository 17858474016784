import React, { useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useEvent } from '~/api'
import Heading from '~/components/Heading'
import Program from '~/components/home/Program'
import PageContainer from '~/components/layout/PageContainer'
import MobileAlert from '~/components/msg/MobileAlert'
import Onboarding from '~/components/Onboarding'
import Paragraph from '~/components/Paragraph'
import { useAuth } from '~/context/auth'

/**
 * Home
 */

const Home = () => {
  // First version all for one event
  const { t } = useTranslation()
  const { user } = useAuth()
  const { event } = useEvent(process.env.REACT_APP_EVENT_ID, user.role)
  const [onboarding, setOnboarding] = useState(false)
  const mixAgenda = [...event.agendas, ...event.sessions]
  mixAgenda.sort((a, b) => (a.start_date > b.start_date ? 1 : -1))

  return (
    <PageContainer>
      <div className="relative">
        <Link
          to="/plenaria"
          className="block shadow rounded-xl"
          style={{ paddingBottom: 'calc(26%)' }}
          onClick={() => {
            ReactGA.event({ category: 'Home', action: 'Herospace', label: 'Click' })
          }}
        >
          <img
            className="rounded-xl absolute top-0 left-0 right-0 bottom-0"
            src={event.event.hero_banner}
            alt="Banner"
          ></img>
        </Link>
      </div>
      <Heading level={1} size="xl" className="mt-6 lg:mt-8">
        {event.event.title}
      </Heading>
      <Paragraph className="mt-6 xl:mt-8">{event.event.description}</Paragraph>
      {!!event.event.onboarding_enable && (
        <ul className="grid gap-6 grid-cols-1 lg:grid-cols-2 pb-6 mt-10">
          <li
            onClick={() => {
              setOnboarding(true)
              ReactGA.event({ category: 'Home', action: 'Banner', label: 'Onboarding' })
            }}
            className="card col-span-1 bg-primary text-white font-bold w-full p-4 text-center rounded-lg shadow relative cursor-pointer"
          >
            {t('home:learn_platform')}
          </li>
          <Link
            to="/soporte-tecnico"
            onClick={() => {
              ReactGA.event({ category: 'Home', action: 'Banner', label: 'Help' })
            }}
          >
            <li className="card col-span- bg-heading text-white font-bold w-full p-4 text-center rounded-lg shadow relative">
              {t('home:help')}
            </li>
          </Link>
        </ul>
      )}
      <Program agendas={mixAgenda} />
      {onboarding && <Onboarding toClose={() => setOnboarding(false)} />}
      {(isMobile || isTablet) && <MobileAlert />}
    </PageContainer>
  )
}

/**
 * Exports
 */

export default Home
